import { Button, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import {
    Nav,
    NavLink,
    NavMenu,
} from './NavbarElements';
import logo from '../goofus.svg';

interface UserData {}

interface Props {
    isLoggedIn: Boolean,
    userData: UserData,
}


const Navbar = (props: Props) => {
    const {
        isLoggedIn = false,
        userData = {}
    } = props

    useEffect(() => {
        console.log('userData', userData)
    }, [userData])

    return (
        <>
            <Nav>
                <NavLink to='/'>
            <img src={logo} className='filter-white' style={{ height: '65%', marginLeft: '1vw', transform: 'scaleX(-1)' }} />
                </NavLink>

                <NavMenu>
                    <NavLink to='/'>
                        Home
                    </NavLink>
                    <NavLink to='/upload'>
                        Upload
                    </NavLink>
                </NavMenu>
                {(isLoggedIn) ? (
                    <Button
                        variant="contained"
                        style={{ margin: 'auto', marginRight: '1vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            axios.get('/report/workplan/session/logout', {
                                headers: {
                                    'Cache-Control': 'no-cache',
                                    'Pragma': 'no-cache',
                                    'Expires': 0
                                }
                            })
                                .then((response) => {
                                    console.log('logout response', response.data)
                                    window.location.href = window.location.origin + '/report'
                                })
                        }}
                    >
                        Logout
                    </Button>
                ) : <></>}


            </Nav>
        </>

    )
}
export default Navbar;
