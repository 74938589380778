import { TextField } from '@mui/material';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
    background: #121212;
    // height: 8vh;
    height: 60px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem calc((100vw - 1100px) / 2);
    z-index: 12;
    /* Thid Nav */
    /* jusify-content: flex-start; */
`;

export const NavLink = styled(Link)`
    color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #29a329;
    }
`;

export const MenuLink = styled(Link)`
    color: #000000;
    font-weight: bold;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #29a329;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;
    /* Second Nav */
    /* margin-right: 24px; */
    /* Third Nav */
    /* width: 100vw;
    white-space: nowrap; */
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;
    /* Third Nav */
    /* justify-content: flex-end;
    width: 100vw; */
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: #ffffff;
    padding: 10px 22px;
    color: #000000;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    /* Second Nav */
    margin-left: 24px;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #808080;
    }
`;

export const Title = styled.p`
    font-size: 1.5em;
    font-weight: bold;
`

export const LinkTile = styled.img`
    height: 100%;
    width: 100%;
    margin: auto;
    align-items: center;
    display: block;
    border-radius: 15px;
    border: 2px solid black;
`

export const InlineLink = styled(Link)`
    // color: #ffffff;
    color: #29a329;
    text-shadow: black 2px 2px;
    // display: flex;
    // align-items: center;
    font-weight: bold;
    text-decoration: none;
    // padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    // &.active {
    //     color: #29a329;
    // }
`

export const Input = styled(TextField)`
    background-color: white;
    border-radius: 5px;
`

export const ExternalLink = styled.a`
    color: #29a329;
    font-weight: bold;
`
