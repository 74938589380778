import React, { useEffect, useState } from 'react';
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'
import Home from './pages/Home'


const App = () => {
  const [loggedIn, setLoggedIn] = useState(true)
  const [userData, setUserData] = useState({})


  return (
    <>
      <div>
        <Router>
          <Navbar isLoggedIn={loggedIn} userData={userData} />
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/upload' element={<div/>}/>
          </Routes>
        </Router>
      </div>
  </>
  );
}

export default App;
