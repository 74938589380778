import logo from '../goofus.svg';
import { TextField, Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react'
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios'
import Markdown from 'react-markdown'

const today: Date = new Date()
const todayDatetime: string = today.toLocaleDateString()
const todayTime: string = today.toLocaleTimeString()

interface Message {
    role: string,
    message: string,
    timestamp: number,
    sources: string[],
}

const capitalizeWords = (words: string) => {
  return words.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
}

const getSources = async (setSources: any, messages: Message[], setMessages: any) => {
    axios.get('/categories')
        .then((response) => {
            setSources(response.data)
            var categories: string = ''
            response.data.map((cat: string, i: number) => {
                if ((response.data.length == 1) || ((response.data.length == 2) && (i == 0))) {
                    categories = `${capitalizeWords(cat)} `
                } else if (i != (response.data.length - 1)) {
                    categories += `${capitalizeWords(cat)}, `
                } else {
                    categories += `and ${capitalizeWords(cat)}`
                }
            })
            setMessages([...messages, {role: 'server', message: `I can answer questions about ${categories}. Ask away!`, timestamp: Date.now(), sources: []}])
        })
}


const query2 = (prompt: String, messages: Message[], setMessages: any, newMessages: Message[], setLoading: any) => {
    var eventSource = new EventSource(`/ask2?query=${prompt}`)
    newMessages.push({role: 'server', message: '', timestamp: Date.now(), sources: []})
    eventSource.onmessage = (event) => {
        // Most Relevant Source will be the last line in the server side event
        if (!event.data.includes('#page') && (!event.data.includes('Close event source connection'))) {
            newMessages.slice(-1)[0]['message'] += event.data.replace(/\\n/g, "\n")
            setMessages([...newMessages])
        } else if (!event.data.includes('Close event')){
            newMessages.slice(-1)[0]['sources'].push(event.data)
            setMessages([...newMessages])
           setLoading(false)
        } else {
            console.log("Closing event source connection..")
            eventSource.close()
        }
    }
    return () => eventSource.close()
}

const submit = (messages: Message[], setMessages: any, prompt: string, setPrompt: any, setLoading: any) => {
    if (prompt != '') {
        const newMessages: Message[] = [...messages, {role: 'user', message: prompt, timestamp: Date.now(), sources: []}]
        setMessages(newMessages)
        setLoading(true)
        query2(prompt, messages, setMessages, newMessages, setLoading)
        setPrompt('')
    }
}

const Home = () => {
    const [sources, setSources] = useState<string[]>([])
    const [messages, setMessages] = useState<Message[]>([])
    const [prompt, setPrompt] = useState('')
    const msgContainer = useRef<HTMLDivElement>(null)
    const [loading, setLoading] = useState<Boolean>(false)

    useEffect(()=>{
        console.log("messages", messages)
        if (msgContainer.current) {
            msgContainer.current.scrollTop = msgContainer.current.scrollHeight
        }
    }, [messages])

    useEffect(()=>{
        getSources(setSources, messages, setMessages)
    }, [])

    useEffect(()=>{
        console.log('sources', sources)
    }, [sources])

    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: 'calc(100vh - 85px)', gap: '20px'}}>
        {/*<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={logo} alt="logo" style={{height: '75px', transform: 'scaleX(-1)'}}/>
            <p style={{color: 'white', fontWeight: 'bold', fontSize: 24, margin: 0}}>Goofus!</p>
        </div>*/}
        <div style={{display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'flex-end',
                     height: 'calc(100% - 35px)',
                     backgroundColor: '#363636',
                     paddingBottom: '30px',
                     border: '2px solid',
                     borderRadius: '5px',
                    }}
        >
            <div ref={msgContainer} style={{width: '65vw', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                <img src={logo} className='filter-gray' style={{ height: '250px', transform: 'scaleX(-1)' }} />
                <p
                    style={{
                        color: 'white',
                        alignSelf: 'center',
                        marginBottom: 0
                    }}
                >[New Session]|{todayDatetime}|{todayTime}</p>
                <p
                    style={{
                        color: 'white',
                        alignSelf: 'center',
                        marginTop: 0
                    }}
                >~~~ Welcome to Goofus! ~~~</p>
                {messages.map((i) => {
                    const date: Date = new Date(i.timestamp)
                    const timestamp: string = date.toLocaleTimeString()
                    var link: string = ''
                    // if (i.filename != '') {
                        // const categoryDict: any = {
                            // dot_administrative_procedures: 'DOTAdminProcs',
                            // doh_administrative_operating_procedures: 'DOHAdminProcs'
                        // }
                        // const category: string = categoryDict[i.filename.split('/')[1]]
                        // const volume: string = String(parseInt(i.filename.split('/').slice(-1)[0].split('_')[0])).padStart(2, '0')
                        // const chapter: string = String(parseInt(i.filename.split('/').slice(-1)[0].split('_')[1].split('.pdf')[0])).padStart(2, '0')
//
                        // const fn = (category == 'DOTAdminProcs') ? `DOTP${volume}${chapter}` : `DOH${volume}${chapter}`
                        // console.log('here')
                        // link = `https://transportation.wv.gov/employees/${category}/${fn}.pdf#page=${i.page}`
//
                    // }
                    return <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: (i.role == 'user') ? '15%' : '10px',
                                marginRight: (i.role == 'server' ? '15%': '10px'),
                            }}
                        >
                        <p
                            style={{color: 'white',
                                fontSize: '0.75em',
                                marginBottom: '-15px',
                                textAlign: (i.role == 'user') ? 'right' : 'left',
                                alignSelf: (i.role == 'user') ? 'flex-end' : 'flex-start',
                            }}
                        >{(i.role == 'user') ? 'User' : 'Goofus'}</p>
                        <p
                            className="messages"
                            style={{backgroundColor: '#636363',
                                fontSize: '1.25em',
                                color: 'white',
                                padding: '7px',
                                //marginLeft: (i.role == 'user') ? '15%' : '10px',
                                // marginRight: (i.role == 'server' ? '15%': '10px'),
                                textAlign: (i.role == 'user') ? 'right' : 'left',
                                alignSelf: (i.role == 'user') ? 'flex-end' : 'flex-start',
                                borderRadius: '10px',
                                marginBottom: 0,
                                width: 'fit-content',
                            }}
                        ><Markdown
                            children={i.message}
                            components={{
                                p: (props) => {
                                    return <p style={{margin: 0}}>{props.children}</p>
                                }
                            }}
                        /></p>
                        <div style={{display: 'flex',
                            textAlign: (i.role == 'user') ? 'right' : 'left',
                            // justifyContent: (i.role == 'user' ? 'right' : 'space-between'),
                            justifyContent: (i.role == 'user' ? 'right' : 'left'),

                        }}>
                            <p
                                style={{color: 'white',
                                        fontSize: '0.75em',
                                        margin: '0 15px',
                                    }}
                            >{timestamp}</p>
                            <div
                                style={{display: 'flex',
                                    flexDirection: 'column',
                                    color: 'white',
                                    fontSize: '0.75em',
                                    }}
                            >
                                {(i.sources.length > 0) ? <>
                                    Most Relevant Sources:
                                    {i.sources.map((source) => {
                                    return <a
                                            style={{color: 'white'}}
                                            href={source}
                                            target='_blank'
                                        >{source}</a>
                                    })}
                                </> : <></>}
                            </div>
                        </div>
                    </div>
                })}
            {(loading) ? <div className='loading' /> : <></>}
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '50px', width: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div style={{width: '64px'}}/>
                    <input
                        style={{width: '100%', padding: '10px', fontSize: '1.3em'}}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode == 13) {
                                submit(messages, setMessages, prompt, setPrompt, setLoading)
                            }
                        }}
                        value={prompt}
                    />
                    <Button
                        // variant='contained'
                        // color='success'
                        style={{height: '50px'}}
                        onClick={() => {
                            submit(messages, setMessages, prompt, setPrompt, setLoading)
                        }}
                    ><SendIcon sx={{color: 'white'}}/></Button>
                </div>
            </div>
        </div>
        {/*<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', visibility: 'hidden'}}>
            <img src={logo} alt="logo" style={{height: '75px'}}/>
            <p style={{color: 'white', fontWeight: 'bold', fontSize: 24}}>Goofus!</p>
        </div>*/}
    </div>
}

export default Home;
